import { UpdateWorkspaceRequest } from '@shared/api/workspaceApi/requests/updateWorkspaceRequest';

export namespace WorkspaceStateActions {
  export class Get {
    static readonly type = '[Workspace] Get workspace';
    constructor(public ownerId: string , public navigate:boolean) {}
  }
  export class Update {
    static readonly type = '[Workspace] Update workspace';
    constructor(public payload: UpdateWorkspaceRequest) {}
  }

  export class ResyncStore {
    static readonly type = '[Workspace] Resync Store';
    constructor(public authCode: string) {}
  }
}
